import { h } from 'vue';
import CoverName from '@/components/Global/MusicPlayer/Fragment/CoverName.vue';
// import Track from '@/components/Global/MusicPlayer/Fragment/Track.vue';
import Remove from '@/components/Global/MusicPlayer/Fragment/Remove.vue';
import ModifyDate from '@/components/Global/MusicPlayer/Fragment/ModifyDate.vue';
import MusicFunction from '@/components/Global/MusicPlayer/MusicFunction.vue';

import PlaylistsNameExpandFunction from '@/components/Global/MusicPlayer/Fragment/PlaylistsNameExpandFunction.vue';
import TrackDivideFunction from '@/components/Global/MusicPlayer/Fragment/TrackDivideFunction.vue';
import VersionAndEditNameFunction from '@/components/Global/MusicPlayer/Fragment/VersionAndEditNameFunction.vue';

export const functionConfig = {
  search: {
    searchType: 'play-history', // 搜尋類別
    placeholder: '搜尋下載紀錄',
  },
  info: {
    list: [

    ],
  },
  listFilter: {
    checkboxs: [
      {
        label: 'Waves',
        value: 'waves',
      },
      {
        label: 'Description',
        value: 'description',
      },

    ],

  },
  functionIcons: {
    list: [

      {
        icon: 'delete',
      },
      {
        icon: 'download',
      },

    ],
  },
  addPlaylistsBtn: {
    visible: true,
  },

};

export const listConfig = {
  columns: [
    {
      type: 'label',
      label: '曲目名稱',
      width: '300px',
      align: 'left',
      key: 'coverName',
      render: CoverName,
    },
    {
      type: 'label',
      label: '',
      // width: '300px',
      align: 'left',
      key: 'owner',
      render: h(MusicFunction, {
        showMusicExpand: true,
      }),
    },

    {
      type: 'label',
      label: '版本',
      width: '50px',
      align: 'left',
      key: 'version',
    },

    {
      type: 'label',
      label: '長度',
      width: '100px',
      align: 'left',
      key: 'length',
    },
    {
      type: 'label',
      label: 'ID',
      width: '100px',
      align: 'left',
      key: 'id',
    },
    {
      type: 'label',
      label: '分軌',
      width: '50px',
      align: 'left',
      key: 'trackNo',
    },

    {
      type: 'label',
      label: '下載日期',
      width: '100px',
      align: 'left',
      key: 'modifyDate',
      render: ModifyDate,
    },
    // {
    //   type: 'label',
    //   label: 'REMOVE',
    //   width: '100px',
    //   align: 'center',
    //   key: 'remove',
    //   render: Remove,
    // },

    {
      type: 'checkbox',
      label: 'C',
      width: '60px',
      align: 'center',
      key: 'checkbox',
    },
    {
      type: 'action',
      label: '',
      width: '60px',
      align: 'left',
      key: 'action',
    },

  ],

};

export const contentData = [
  {
    coverName: 'Bags of Fun',
    owner: 'vicky',
    track: 10,
    modifyDate: '27 Aug 2021',
    remove: 'x',
    length: '03:19',
    trackNo: 5,
    version: 3,
    id: 'MAT305-76',

  },
  {
    coverName: 'Silver',
    name: 'becky',
    track: 3,
    modifyDate: '27 Aug 2021',
    remove: 'x',
    length: '03:19',
    trackNo: 5,
    version: 2,
    id: 'MAT305-76',
  },
];

export const listDetailColumns = [
  {
    type: 'label',
    label: '曲目名稱',
    align: 'left',
    key: 'playlistsName',
    render: PlaylistsNameExpandFunction,

  },
  {
    type: 'label',
    label: '版本',
    width: '100px',
    align: 'left',
    key: 'version',
  },
  {
    type: 'label',
    label: '長度',
    width: '100px',
    align: 'left',
    key: 'length',
  },
  {
    type: 'label',
    label: 'ID',
    width: '100px',
    align: 'left',
    key: 'id',
  },
  {
    type: 'label',
    label: '分軌',
    width: '100px',
    align: 'left',
    key: 'trackNo',
  },
  {
    type: 'label',
    label: 'REMOVE',
    width: '100px',
    align: 'center',
    key: 'remove',
    render: Remove,
  },
  {
    type: 'checkbox',
    label: 'C',
    width: '60px',
    align: 'center',
    key: 'checkbox',
  },
  {
    type: 'action',
    label: '',
    width: '60px',
    align: 'left',
    key: 'action',
  },
];

export const listDetailContentData = [
  {
    playlistsName: '我的最愛',
    owner: 'vicky',
    track: 10,
    modifyDate: '27 Aug 2021',
    remove: 'x',
    length: '12:32',
    version: 5,
    trackNo: 5,
    id: 'MAT305-5',

  },
  {
    playlistsName: '哈哈',
    name: 'becky',
    track: 3,
    modifyDate: '27 Aug 2021',
    remove: 'x',
    length: '03:19',
    trackNo: 5,
    version: 3,
    id: 'MAT305-76',
  },
];

export const musicDetailColumns = [
  {
    type: 'label',
    label: '曲目名稱',
    width: '300px',
    align: 'left',
    key: 'playlistsName',
    render: VersionAndEditNameFunction,
  },
  {
    type: 'label',
    label: '',
    // width: '300px',
    align: 'left',
    key: 'owner',
    render: h(MusicFunction, {
      showExpand: false,
      ml: true,
    }),
  },
  {
    type: 'label',
    label: '長度',
    width: '50px',
    align: 'left',
    key: 'versio',
  },
  {
    type: 'label',
    label: '長度',
    width: '100px',
    align: 'left',
    key: 'length',
  },
  {
    type: 'label',
    label: 'ID',
    width: '100px',
    align: 'left',
    key: 'id',
  },

  {
    type: 'label',
    label: '分軌',
    width: '50px',
    align: 'left',
    key: 'trackNo',
  },

  {
    type: 'label',
    label: 'REMOVE',
    width: '100px',
    align: 'center',
    key: 'remove',
  },
  {
    type: 'checkbox',
    label: 'C',
    width: '60px',
    align: 'center',
    key: 'checkbox',
  },
  {
    type: 'label',
    label: 'none',
    width: '60px',
    align: 'left',
    // key: 'action',
  },
];

export const musicDetailContentData = [
  {
    name: 'Full length',
    extraInfo: 'Vocal Lead',
    playlistsName: '我的最愛',
    owner: 'vicky',
    track: 10,
    modifyDate: '27 Aug 2021',
    length: '12:32',
    version: 5,
    id: 'MAT305-5',

  },
  {
    name: 'Full length',
    extraInfo: 'Vocal Lead',
    playlistsName: '哈哈',
    track: 3,
    modifyDate: '27 Aug 2021',
    length: '03:19',
    version: 3,
    id: 'MAT305-76',
  },
];

export const trackDivideColumns = [
  {
    type: 'label',
    label: '曲目名稱',
    width: '300px',
    align: 'left',
    key: 'playlistsName',
    render: TrackDivideFunction,
  },
  {
    type: 'label',
    label: '',
    // width: '300px',
    align: 'left',
    key: 'owner',
    render: h(MusicFunction, {
      showExpand: false,
      ml: true,
    }),
  },
  {
    type: 'label',
    label: '',
    width: '50px',
    align: 'left',
    key: 'dsf',
  },
  {
    type: 'label',
    label: '長度',
    width: '100px',
    align: 'left',
    key: 'length',
  },
  {
    type: 'label',
    label: 'ID',
    width: '100px',
    align: 'left',
    key: 'id',
  },
  {
    type: 'label',
    label: '分軌',
    width: '50px',
    align: 'left',
    key: 'trackNo',
  },
  {
    type: 'label',
    label: '分軌',
    width: '100px',
    align: 'left',
    key: 'trackfdsNo',
  },
  {
    type: 'label',
    label: 'REMOVE',
    width: '100px',
    align: 'center',
    key: 'remove',
  },
  {
    type: 'checkbox',
    label: 'C',
    width: '60px',
    align: 'center',
    key: 'checkbox',
  },
  {
    type: 'label',
    label: 'C',
    width: '60px',
    align: 'center',
    key: 'empty',

  },

];

export const trackDivideData = [
  {
    name: 'Clarinet&Basso',
    extraInfo: 'Vocal Lead',
    playlistsName: '我的最愛',
    owner: 'vicky',
    track: 10,
    modifyDate: '27 Aug 2021',
    length: '12:32',
    version: 5,
    id: 'MAT305-5',

  },
  {
    name: 'Low Pizzicato',
    extraInfo: 'Vocal Lead',
    playlistsName: '哈哈',
    track: 3,
    modifyDate: '27 Aug 2021',
    length: '03:19',
    version: 3,
    id: 'MAT305-76',
  },
];
